<template>
  <div id="app">
    <div class="invitation">
      <my-invitation></my-invitation>
    </div>

  </div>
</template>

<script>
import MyInvitation from './components/MyInvitation.vue'
import MyInvitationNo from './components/MyInvitationNo.vue';

export default {
  name: 'App',
  components: {
    MyInvitation,
    MyInvitationNo
  },
  data(){
    return {
      selectedInvitado: null,
    }
  },
  mounted(){
    setTimeout(() => {
      const key = this.$route.params.key;
      //console.log(this.invitados[key]);
      this.selectedInvitado = key;
    }, 200);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 100%;
  margin: 0px auto;
}

.invitation{
  width: 100%;
  margin: 0px auto;
  /* margin-bottom: 30px; */
  max-width: 400px;
}
</style>
