<script>
import moment from 'moment'
import invita from '../assets/invitados.json'
export default {
    data() {
        return {
            dateTarget: moment('2024-12-19T15:30:00'),
            loadingData: true,
            loadingSend: false,
            timeLeft: null,
            userAgent: false,
            dark: false,
            key: null,
            activeMessage: false,
            selectedInvitado: null,
            bookingDisabled: false,
            invitados: invita,
            optionsAssistants: [],
            audio_play: false,
            itinerary: [
                {
                    time: '03:30 PM',
                    title: 'CEREMONIA',
                    img: 'img/icons/church.png',
                },
                {
                    time: '05:00 PM',
                    title: 'RECEPCIÓN',
                    img: 'img/icons/venue.png',
                },
                {
                    time: '06:00 PM',
                    title: 'VALS',
                    img: 'img/icons/dance.png',
                },
                {
                    time: '06:30 PM',
                    title: 'BRINDIS',
                    img: 'img/icons/cheer.png',
                },
                {
                    time: '07:00 PM',
                    title: 'CENA',
                    img: 'img/icons/food.png',
                },
                {
                    time: '08:00 PM',
                    title: 'FIESTA',
                    img: 'img/icons/party.png',
                },
            ]
        }
    },
    computed: {
        days() {
            if(this.timeLeft){
                return Math.floor(this.timeLeft.asDays());
            }
        },
        hours(){
            if(this.timeLeft){
                return Math.floor(this.timeLeft.asHours() % 24);
            }
        },
        minutes(){
            if(this.timeLeft){
                return Math.floor(this.timeLeft.asMinutes() % 60);
            }
        },
        seconds() {
            if(this.timeLeft){
                return Math.floor(this.timeLeft.asSeconds() % 60);
            }
        },
        styleLogo() {
            return this.userAgent && !this.dark ? 'text-color' : 'text-color';
        },
        styleDark() {
            return this.userAgent && !this.dark ? 'fondo-none' : '';
        }
    },
    watch:{
        'form.confirmation'(nVal) {
            this.setDisabled(nVal);
        }
    },
    mounted(){
        this.updateTime();
        this.loadingData = true;
        setInterval(this.updateTime, 1000);
        localStorage.clear();
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            // El sistema está en modo oscuro
            this.dark = true;
            console.log('Modo oscuro');
        } else {
            // El sistema está en modo claro
            console.log('Modo claro');
        }
        if (navigator.userAgent.includes("Samsung")) {
            // El dispositivo es un Samsung
            this.userAgent = true;
            console.log('Es un dispositivo Samsung');
        } else {
            // No es un dispositivo Samsung
            console.log('No es un dispositivo Samsung');
        }
        setTimeout(() => {
            const localData = localStorage.getItem('invitado');
            if(localData){
                this.selectedInvitado = JSON.parse(localData);
                this.arrayNumero(this.selectedInvitado?.assistants);
            }else{
                this.key = this.$route.params.key;
                console.log(this.invitados[this.key]);
                this.selectedInvitado = this.invitados[this.key];
                if(this.selectedInvitado){
                    localStorage.setItem('invitado', JSON.stringify(this.invitados[this.key]));
                }
                this.arrayNumero(this.selectedInvitado?.assistants);
            }
            this.loadingData = false;
        }, 200);
    },
    methods: {
        setDisabled(confirm){
            if(confirm && confirm.value == 'NO_ASISTIRE'){
                console.log('cambie', confirm);
                this.bookingDisabled = true;
                this.form.assistants = null;
            }else{
                this.bookingDisabled = false;
            }
        },
        updateTime() {
            const currentDate = moment();
            this.timeLeft = moment.duration(this.dateTarget.diff(currentDate));
        },
        range(start, end) {
            // Crea un rango numérico desde 'start' hasta 'end'
            return Array.from({ length: end - start + 1 }, (_, i) => start + i);
        },
        
        arrayNumero(assistants){
            for (let i = 1; i <= assistants; i++) {
                this.optionsAssistants.push({
                    value: i,
                    label: `${i} ${i>1?'invitados':'invitado'}`
                });
            }
        },
        sendMessage(){
            const phone_she = "+50371853028";
            const phone_he = "+50375119202";
            const _fam = this.selectedInvitado;
            const _text = `Hola soy, ${_fam.name}.\n\nConfirmo mi asistencia a tu boda \n\n¡Nos vemos pronto!`;
            const phone = _fam.confirm == "she" ? phone_she : phone_he;
            const encodeText = encodeURIComponent(_text);
            // Construye el enlace de intercambio de WhatsApp
            const whatsappLink = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeText}`;

            // Abre la ventana de WhatsApp con el enlace de intercambio
            window.open(whatsappLink, '_blank');
        },
        resetForm(){
            this.form = {
                confirmation: null,
                assistants: null,
            }
        },
        playAudio(){
            const vm = this;
            vm.audio_play = true;
            setTimeout(() => {
                console.log(this.$refs.audioPlayer);
                this.$refs.audioPlayer.play();
                vm.$refs.audioPlayer.muted = false; // Activar el audio después de 100ms
            }, 100);
        },
        pauseAudio(){
            const vm = this;
            vm.audio_play = false;
            this.$refs.audioPlayer.pause();
        },
        hasLoaded() {
            let vm = this;
            setTimeout(() => {
                //vm.playAudio();
            }, 500);
        },

    }
}
</script>

<template>
    <div>
        <div class="w-full">
            <div class="image-container relative w-full h-[60vh]">
                <img src="../assets/25.jpeg" class="w-full h-full object-cover relative"
                    style="object-position: center calc(50% - 60px);"
                />
                <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: gray; opacity: 0.35"></div>
            </div>
        </div>
        <div class="invitation-logo fondo1 flex justify-center flex-col pt-10">
            <h1 class="title-logo playfair-display-regular text-7xl mt-[-10px] animate__animated animate__backInLeft animate__delay-0.25s tracking-wider" >JASSON <br> & ROSA </h1>
            <h1 class="title-logo italianno-regular text-5xl mt-2 animate__animated animate__tada animate__delay-0.25s" :class="styleLogo">¡Nos Casamos!
            </h1>
            <p class="text-lg">Presiona el <!-- <img src="../assets/icons/heart-solid.svg" width="15px"  class="inline-block"/> -->
                <svg class="inline-block" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 512 512"><path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/></svg> para escuchar nuestra canción
            </p>
            <div class="w-full flex justify-center items-center flex-col p-3 gap-4">
                <audio ref="audioPlayer" controls class="custom-audio" @loadedmetadata="hasLoaded">
                    <source src="../assets/audio/audio_2.mp3" type="audio/mpeg">
                </audio>
                <div class="inline-block cursor-pointer bg-gray-400 rounded-full p-4" @click="!audio_play ? playAudio() : pauseAudio()">
                    <svg class="inline-block animate__animated animate__heartBeat animate__infinite" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512"><path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/></svg>
                </div>
            </div>
            <div class="text-center flex justify-center items-center flex-col px-4">
                <p class="animate__animated animate__bounce animate__delay-0.25s p-3 text-lg font-medium" >
                    Dios nos permitió que nos encontráramos y próximamente nos dará su bendición para ser instrumentos de su amor y de su gracia como esposos. &#161;La felicidad se completa!
                </p>
                <img src="../assets/rosa1.png" width="200" />
                <p class="animate__animated animate__bounce animate__delay-0.25s p-3 text-lg font-medium" >
                    Con la bendición de nuestros padres
                </p>
            </div>
            <div class="text-center flex justify-center items-center flex-col px-4">
                <p class="animate__animated animate__bounce italianno-regular animate__delay-0.25s text-4xl font-bold" >
                    Padres del Novio
                </p>
                <p class="animate__animated animate__bounce animate__delay-0.25s text-lg font-medium" >
                    Gloria de Serrano <br> Nelson Serrano
                </p>
                <p class="animate__animated animate__bounce italianno-regular animate__delay-0.25s pt-4 text-4xl font-bold" >
                    Padres de la Novia
                </p>
                <p class="animate__animated animate__bounce animate__delay-0.25s text-lg font-medium" >
                    Rosa Maria Euceda <br> Juan Pablo Rodriguez
                </p>
                <p class="animate__animated animate__bounce italianno-regular animate__delay-0.25s pt-4 text-4xl font-bold" >
                    Padrinos
                </p>
                <p class="animate__animated animate__bounce animate__delay-0.25s text-lg font-medium" >
                    Miriam Serrano <br> Jose Alcides Serrano
                </p>
            </div>

            <div class="text-center flex justify-center items-center flex-col p-4">
                <p class="animate__animated animate__bounce italianno-regular animate__delay-0.25s text-4xl font-bold" >
                    El gran dia empieza en
                </p>
                <div class="cronometro animate__animated animate__pulse">
                    <div class="tiempo">
                        <div class="dia">{{ days }} <span class="text-xs font-extralight">DIAS</span></div>
                        <div> : </div>
                        <div class="hora">{{ hours }} <span class="text-xs font-extralight">HORAS</span></div>
                        <div> : </div>
                        <div class="minuto">{{ minutes }} <span class="text-xs font-extralight">MINUTOS</span></div>
                        <div> : </div>
                        <div class="segundo">{{ seconds }} <span class="text-xs font-extralight">SEGUNDOS</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fondo1 flex justify-center flex-col py-4 relative w-full">
            <img src="../assets/rosa2.png" class="absolute w-56 top-8 opacity-40" />
            <div class="w-full z-10 flex justify-center flex-col py-4 relative items-center gap-4">
                <h1 class="title-logo italianno-regular text-5xl mt-2 animate__animated animate__tada animate__delay-0.25s" :class="styleLogo">¡Nos Casamos!
                </h1>
                <div class="w-full flex flex-col justify-center items-center">
                    <div class="w-full text-center font-semibold">
                        Diciembre
                    </div>
                    <div class="w-full flex gap-5 justify-center items-center">
                        <div class="text-2xl w-1/4 border-y-2 border-y-gray-700">
                            Jueves
                        </div>
                        <div class="text-4xl">
                            19
                        </div>
                        <div class="text-2xl w-1/4 border-y-2 border-y-gray-700">
                            2024
                        </div>
                    </div>
                </div>
                <div>
                    <img src="../assets/rings.png" width="150" />
                </div>
                <div class="w-full flex flex-col gap-4">
                    <div class="w-full flex flex-col gap-4 justify-center items-center">
                        <img src="../assets/icons/church.png" width="75"/>
                        <div class="flex flex-col w-full justify-center items-center gap-2">
                            <p class="italianno-regular text-5xl">Ceremonia religiosa</p>
                            <p class="flex flex-col">
                                <span>Hora: 3:30 PM</span>
                                <span>Templo del Divino Niño Jesús</span>
                                <span>Ciudad Jardín - San Miguel</span>
                            </p>
                            <a href="https://maps.app.goo.gl/spe6LJsp4HLUcX2eA" target="_blank" class="bg-[#867113] rounded-full px-5 font-semibold text-white cursor-pointer w-1/3">Ver Mapa</a>
                        </div>
                    </div>
                    <div class="w-full flex flex-col gap-4 justify-center items-center">
                        <img src="../assets/icons/cheer.png" width="75"/>
                        <div class="flex flex-col w-full justify-center items-center gap-2">
                            <p class="italianno-regular text-5xl">Recepción</p>
                            <p class="flex flex-col p-3">
                                <span>Hora: 5:00 PM</span>
                                <span>Bellanova - jardín</span>
                                <span>Carretera panamericana, salida a la Unión, entrada a col. Carrillo #1 , San Miguel, El Salvador</span>
                            </p>
                            <a href="https://maps.app.goo.gl/1ZPqbuRmEq8ySENR8" target="_blank" class="bg-[#867113] rounded-full px-5 font-semibold text-white cursor-pointer w-1/3">Ver Mapa</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fondo1 flex justify-center flex-col py-4 relative w-full">
            <div class="w-full z-10 flex justify-center flex-col relative items-center gap-4">
                <div class="flex flex-col w-full justify-center items-center gap-2">
                    <p class="italianno-regular text-5xl">Código de vestimenta</p>
                    <p class="flex flex-col p-3 font-bold">
                        Semiformal
                    </p>
                    <img src="../assets/icons/dress.png" class="w-20" />
                    <!-- <p class="">
                        Color blanco: reservado para la novia
                    </p> -->
                </div>
            </div>
        </div>
        <div class="fondo1 flex justify-center flex-col py-4 relative w-full">
            <img src="../assets/rosa2.png" class="absolute w-56 top-8 opacity-40" />
            <div class="w-full z-10 flex justify-center flex-col relative items-center gap-4">
                <div class="flex flex-col w-full justify-center items-center gap-2">
                    <p class="italianno-regular text-5xl">Itinerario</p>
                    <div class="flex items-center flex-col w-4/5">
                        <div v-for="(item, idx) in itinerary" :key="idx" class="flex items-center w-full" :class="{
                            'flex-row-reverse': (idx % 2) !== 0
                        }" >
                            <div class="flex justify-center w-[40%]">
                                <img :src="item.img" class="w-16" alt="" />
                            </div>
                            <div class="flex flex-col justify-center items-center w-[20%] relative h-20">
                                <div class="w-8 h-8 bg-gray-300 rounded-full flex items-center justify-center absolute ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="currentColor" viewBox="0 0 512 512">
                                        <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/>
                                    </svg>
                                </div>
                                <div class="w-px bg-gray-700 h-full"></div>
                            </div>
                            <div class="flex flex-col justify-center items-center w-[40%]">
                                <span class="text-md font-semibold">{{ item.time }}</span>
                                <span class="text-gray-600 text-sm">{{ item.title }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fondo1 flex justify-center flex-col py-4 relative w-full" v-if="selectedInvitado">
            <div class="w-full z-10 flex justify-center flex-col relative items-center gap-4">
                <div class="flex flex-col w-full justify-center items-center gap-2">
                    <p class="italianno-regular text-5xl">Reservado: </p>
                    <p class="flex flex-col p-3 text-2xl font-bold">
                        {{ selectedInvitado.assistants }} {{ selectedInvitado.assistants == 1 ? 'Persona' : 'Personas' }}
                    </p>
                    <img src="../assets/icons/table.png" class="w-20" />
                </div>
            </div>
        </div>
        <div class="fondo1 flex justify-center flex-col py-4 pb-20 relative w-full" v-if="selectedInvitado">
            <div class="w-full z-10 flex justify-center flex-col relative items-center gap-4">
                <div class="flex flex-col w-full justify-center items-center gap-2">
                    <p class="italianno-regular text-5xl">Confirmar Asistencia</p>
                    <p class="flex flex-col p-3">
                        ¡Tu presencia es muy importante para nosotros! <br>Te invitamos a confirmar tu asistencia por WhatsApp antes del 9 de diciembre.
                    </p>
                    <a @click="sendMessage()" target="_blank" class="bg-[#867113] rounded-full px-5 font-semibold text-white cursor-pointer w-1/3">Confirmar</a>
                </div>
                <div class="playfair-display-regular text-7xl">
                    J|R
                </div>

                
                <div class="text-2xl py-3">
                    ¡Te esperamos!
                </div>
            </div>
            <img src="../assets/footer.png" class="absolute bottom-0" />
        </div>
    </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Imperial+Script&family=Italianno&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

.playfair-display-regular {
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}
.playfair-display-bold {
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
}

.italianno-regular {
    font-family: "Italianno", cursive;
    font-weight: 400;
    font-style: normal;
}


.imperial-script-regular {
    font-family: "Imperial Script", cursive;
    font-weight: 400;
    font-style: normal;
}

.custom-audio {
  width: 100%; /* Ajusta el ancho según tus necesidades */
  appearance: none; /* Oculta los controles predeterminados en algunos navegadores */
}

.custom-audio::-webkit-media-controls-enclosure {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-audio::-webkit-media-controls-play-button,
.custom-audio::-webkit-media-controls-current-time-display,
.custom-audio::-webkit-media-controls-time-remaining-display,
custom-audio::-webkit-media-controls-download-button {
    display: none;
}


.invitation-logo{
    background-repeat: no-repeat;
    width: 100%;
    max-height: auto;
}
.invitation-phrase{
    background-repeat: no-repeat;
    width: 100%;
    max-height: auto;
}
.invitation-crono{
    background-repeat: no-repeat;
    width: 100%;
    max-height: auto;
}
.invitation-confirma{
    background-repeat: no-repeat;
    width: 100%;
    max-height: auto;
}
.invitation-ceremonia{
    background-repeat: no-repeat;
    width: 100%;
    max-height: auto;
}

.fondo1{
    background-image: url('../assets/fondo_main.jpg');
    background-size: cover
}

.fondo2{
    background-image: url('../assets/flowers.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.fondo3{
    background-image: url('../assets/fondo3.png');
    background-size: 100% 100%;
}
.fondo4{
    background-image: url('../assets/fondo4.png');
    background-size: 100% 100%;
}
.fondo5{
    background-image: url('../assets/fondo5.png');
    background-size: 100% 100%;
}
.fondo6{
    background-image: url('../assets/fondo6.png');
    background-size: 100% 100%;
}
.fondo7{
    background-image: url('../assets/fondo7.png');
    background-size: 100% 100%;
}
.fondo8{
    background-image: url('../assets/fondo8.png');
    background-size: 100% 100%;
}
.fondo-none{
    background-image: none !important;
    background-color: white;
    background-size: 100% 100%;
}

.form-control{
    border: 1px solid rgba(#C49988, 0.4);
    width: 100%;
    padding: 10px;
}

.btn-mapa{
    text-decoration: none;
    cursor: pointer;
    background-color: rgb(128, 84, 30);
    padding: 15px 25px;
    border-radius: 10px;
    color: white;
    margin: 15px;
}
.btn-mapa:hover{
    background-color: rgb(56, 31, 1);
    color: white;
}

.text-color{
    color: #C49988;
}
.text-font{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.text-font2{
    font-family: 'Great Vibes', regular;
}
.line{
    background-color: #C49988;
    width: 2px;
    height: 50px;
    opacity: 0.5;
}

.logo{
    margin-top: 1em;
}

.title-logo{
    color: black;
    margin-bottom: 5px !important;
}

.text-date{
    color: #C49988;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.cronometro {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    max-width: 350px;
    color: black;
    margin: 0 auto;
    border-radius: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}



.text-color-dark{
    color: #ffffff !important;
}

.cronometro-dark {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    max-width: 350px;
    color: white;
    border: 1px solid #C49988;
    margin: 0 auto;
    border-radius: 20px;
    background-color: #f0c8b8;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.tiempo {
    display: flex;
    align-items: center;
}

.dia,
.hora,
.minuto,
.segundo {
    margin: 0 2px;
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.dia:before,
.hora:before,
.minuto:before,
.segundo:before {
    content: "";
}

.dia:after,
.hora:after,
.minuto:after,
.segundo:after {
    content: "";
}

span {
    font-weight: bold;
}
/* Estilo para el tag seleccionado */
.multiselect__tag {
    background-color: rgb(128, 84, 30) !important; /* Verde */
    color: #fff; /* Texto blanco */
}

/* Estilo para el botón de eliminación en el tag */
.multiselect__tag-icon {
    color: #fff; /* Icono blanco */
}

/* Estilo para los tags desplegados */
.multiselect__tags {
    border: 1px solid rgb(128, 84, 30) !important; /* Borde verde */
}

/* Estilo para el input del multiselect */
.multiselect__input {
    border: 1px solid rgb(128, 84, 30); /* Borde verde */
}
.multiselect__option--highlight {
    background-color: rgb(128, 84, 30) !important;
}
.multiselect__option--selected {
    background-color: rgb(128, 84, 30) !important;
}
</style>